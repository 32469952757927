<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
      <template #left>
        <Button v-permission="['1010','1111','yhtj']" style="background: #12A25D;border-color: #12A25D" label="添加用户"
                icon="pi pi-plus" class="p-mr-2"
                @click="showDialogAsCreate()"/>
      </template>

      <template #right>
        <div class="p-inputgroup">
          <InputText @keypress="$event.keyCode==13?query():{}" style="width:200px;"
                     v-model="dataTable.queryParams.nameOrTelephone" placeholder="请输入姓名或者手机号码"/>
          <Button @click="query()" icon="pi pi-search" style="background: #FFFFFF;color: #000000;opacity: 0.25;"/>
        </div>
      </template>
    </Toolbar>

    <DataTable :scrollable="true" :resizableColumns="true" columnResizeMode="expand" :rowHover="true"
               :value="dataTable.gridData" :lazy="true" @page="onPage($event)"
               :totalRecords="dataTable.totalRecords"
               responsiveLayout="scroll" :paginator="true" :rows="10"
               paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共 {totalRecords} 条"
               class="ss">
      <template #empty>
        没有发现数据。
      </template>
      <Column field="name" header="用户姓名"></Column>
      <Column field="telephone" header="手机号码"></Column>
      <Column field="email" header="电子邮箱"></Column>
      <Column field="role" header="角色">
        <template #body="slotProps">
          <span v-if="slotProps.data.roleId" class="admin-status">
            <label style="display: block;text-align: center;line-height: 22px">{{
                slotProps.data.roleName
              }}</label>
          </span>
        </template>
      </Column>
      <Column field="team" header="所属团队"></Column>
      <Column frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:15rem;">
        <template #body="slotProps">
          <a v-permission="['1010','1111','yhbj']"
             style="padding-right:8px; margin:0;border-right:1px solid rgba(216, 216, 216, 1);" href="javascript:"
             class="a-button" @click="showDialogAsEdit(slotProps.data)">编辑</a>
          <a v-permission="['1010','1111','czmm']"
             style="padding:0 8px;margin:0;border-right:1px solid rgba(216, 216, 216, 1);" href="javascript:"
             class="a-button" @click="resetPassword(slotProps.data)">重置密码</a>
          <a v-permission="['1010','1111','yhsc']" href="javascript:" class="a-button"
             @click="openRemove(slotProps.data)">删除</a>
        </template>
      </Column>
      <template #paginatorLeft></template>
      <template #paginatorRight>
        <div class="p-field">
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;"
                       id="integeronly"
                       v-model="dataTable.jumpPageNum"/>
          <label>&nbsp;&nbsp;页</label>
        </div>
      </template>
    </DataTable>

    <Dialog v-model:visible="dialog.show" :style="{width: '450px'}" :header="dialog.title" :modal="true" class="p-fluid"
            content-style="padding:0" @hide="hideDialog">
      <form @submit.prevent="submit(v$.$invalid)"
            style="padding:32px 21px 21px 21px;" class="p-fluid addUser">

        <div class="p-field p-grid myInput">
          <label for="name" class="p-col-fixed" style="width:100px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>用户姓名:
          </label>
          <div class="p-col">
            <InputText id="name" v-model.trim="v$.form.name.$model"/>
            <small class="p-error" v-if="v$.form.name.$invalid && form.$submitted">请输入用户姓名</small>
          </div>
        </div>
        <div class="p-field p-grid myInput">
          <label for="telephone" class="p-col-fixed" style="width:100px;justify-content: flex-end"><span
            style="color:red;margin-right: 4px;">*</span>手机号码:</label>
          <div class="p-col">
            <InputText id="telephone" v-model.trim="v$.form.telephone.$model"/>
            <small class="p-error" v-if="v$.form.telephone.$invalid && form.$submitted">请输入正确的手机号码</small>
          </div>
        </div>
        <div class="p-field p-grid myInput">
          <label for="role" class="p-col-fixed" style="width:100px;justify-content: flex-end"><span
            style="color:red;margin-right: 4px;">*</span>用户权限:</label>
          <div class="p-col">
            <Dropdown id="role" v-model="v$.form.roleId.$model"
                      :options="roleList" optionLabel="name"
                      optionValue="id"
                      placeholder="请选择角色"/>
            <small class="p-error" v-if="v$.form.roleId.$invalid && form.$submitted">请选择角色</small>
          </div>
        </div>
        <div class="p-field p-grid myInput">
          <label for="email" class="p-col-fixed"
                 style="width:100px;justify-content: flex-end;padding-left:16px;">电子邮箱:</label>
          <div class="p-col">
            <InputText id="email" v-model.trim="v$.form.email.$model"/>
            <small class="p-error" v-if="v$.form.email.$invalid && form.$submitted">请输入正确的邮箱地址</small>
          </div>
        </div>

        <div class="p-field p-grid myInput">
          <label for="team" class="p-col-fixed"
                 style="width:100px;justify-content: flex-end;padding-left:16px;">所属团队:</label>
          <div class="p-col">
            <InputText id="team" v-model.trim="v$.form.team.$model"/>
          </div>
        </div>
        <div class="p-dialog-footer" style="padding:12px 0 8px 21px;border-top: 1px solid rgba(0, 0, 0, 0.09)">
          <Button style="border-color: #12A25D;color: #12A25D" label="取消" class="p-button-outlined"
                  @click="hideDialog"/>
          <Button style="background: #12A25D;border-color: #12A25D;margin: 0" type="submit"
                  :label="dialog.submitButtonText" icon="pi pi-check"/>
        </div>
      </form>

    </Dialog>

    <ResetPassword ref="resetPasswordDialog" :show="true"></ResetPassword>
    <Toast/>

    <Dialog v-model:visible="deleteDialog" header="删除用户" style="width:433px;height: 136px" :closable="false">
      <p style="line-height: 24px; display: flex;height: 24px">

        <img style="width:24px;height: 24px;display: inline-block;padding: 3px;margin-right: 3px"
             src="../../../assets/images/close.png">

        确定要删除此用户吗？</p>
      <template #footer>
        <Button label="取消" @click="closeRemove"
                style="width:65px;border-color: #12A25D;color: #12A25D;background: #FFFFFF"></Button>
        <Button label="确认" @click="remove(deleteRow)"
                style="width:65px;background: #12A25D;border-color: #12A25D"></Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ResetPassword from "./ResetPassword";

export default {
  components: { ResetPassword },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      deleteDialog: false,
      deleteRow: null,
      form: {
        id: null,
        name: null,
        telephone: null,
        role: null,
        email: null,
        team: null,
        roleId: null,
        $submitted: false,
        $formMode: "create",
      },
      dialog: {
        show: false,
        title: "",
        submitButtonText: ""
      },
      breadcrumb: {
        home: { label: '系统管理', to: '#' },
        items: [
          { label: '用户列表', to: '#' }
        ],
      },
      dataTable: {
        gridData: [],
        queryParams: {
          nameOrTelephone: null
        },
        lazyParams: {
          page: 0
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
        lastParams: null
      },
      resetPasswordDialogShow: false,
      resetPasswordRow: {},
      roleList: [],

    }
  },
  mounted() {

    this.loadTable()
    this.getRoleList()
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        email: {
          email
        },
        telephone: {
          required,
          "format": helpers.regex(/^1\d{10}$/)
        },
        role: {},
        team: {},
        roleId: { required },
      }
    }
  },
  methods: {
    getRoleList() {
      this.$http('/role/all', 'get', null, res => {
        this.roleList = res.data
      })
    },

    resetPassword(row) {
      // this.$refs.resetPasswordDialog.show(row)
      this.$confirm.require({
        header: '确认',
        message: '确认要重置密码吗？',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: '确认',
        rejectLabel: '取消',
        accept: () => {
          this.$http("/user/resetPassword4Admin", "get", { telephone: row.telephone }, () => {
            this.$toast.add({
              severity: 'success',
              summary: '成功',
              detail: '密码重置成功，短信已发送到该手机！',
              life: 3000
            });
          })
        }
      });
    },

    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable(this.dataTable.lastParams);
      }
    },

    query() {
      let queryResult = {};
      if (this.dataTable.queryParams.nameOrTelephone) {
        if (/^1\d{10}$/.test(this.dataTable.queryParams.nameOrTelephone)) {
          queryResult.telephone = this.dataTable.queryParams.nameOrTelephone;
        } else {
          queryResult.name = this.dataTable.queryParams.nameOrTelephone;
        }
      }
      this.loadTable(queryResult);
    },

    /**
     * 重置表单
     */
    resetForm() {
      for (let i in this.form) {
        this.form[i] = null;
      }
    },

    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;
      this.dataTable.lastParams = params;
      let targetParmas = { "current": this.dataTable.lazyParams.page + 1, "size": this.dataTable.lazyParams.rows };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.$http('/user/list', 'get', targetParmas, function(response) {
        me.dataTable.gridData = response.data.records;
        me.dataTable.totalRecords = response.data.total;
        me.dataTable.loading = false;
      })
    },

    onPage(event) {
      this.dataTable.lazyParams = event;
      this.loadTable(this.dataTable.lastParams);
    },

    /**
     * 隐藏对话框
     */
    hideDialog() {
      this.dialog.show = false;
      this.resetForm();
    },

    /**
     * 以创建模式打开对话框
     */
    showDialogAsCreate() {
      this.form.$formMode = "create";
      this.resetForm();
      this.dialog = {
        show: true,
        title: "添加用户",
        submitButtonText: "创建"
      };
    },

    /**
     * 以编辑模式打开对话框
     * @param row
     */
    showDialogAsEdit(row) {
      let me = this;
      this.form.$formMode = "edit";
      this.resetForm();
      this.dialog = {
        show: true,
        title: "编辑用户",
        submitButtonText: "保存"
      };
      this.$http('/user/get', 'get', { "id": row.id }, function(response) {
        me.form = response.data;
      })
    },

    /**
     * 删除记录
     * @param row
     */
    openRemove(row) {
      this.deleteDialog = true
      this.deleteRow = row
    },
    closeRemove() {
      this.deleteDialog = false
      this.deleteRow = null
    },

    remove(row) {
      let me = this;
      me.$http('/user/remove', 'get', { ids: row.id }, function() {
        me.$toast.add({ severity: 'success', summary: '成功', detail: '用户删除成功！', life: 3000 });
        me.loadTable(me.dataTable.lastParams)
        me.deleteDialog = false;
      })
    },

    /**
     * 提交表单
     * @param isInvalid
     */
    submit(isInvalid) {
      let me = this;
      this.form.$submitted = true;
      if (isInvalid) {
        return;
      }

      this.$confirm.require({
        header: '确认',
        message: '确认要执行此操作吗？',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: '确认',
        rejectLabel: '取消',
        accept: () => {
          this.$http('/user/saveOrUpdate', 'post', this.form, () => {
            me.$toast.add({
              severity: 'success',
              summary: '成功',
              detail: me.form.id == null ? '用户添加成功！' : '修改成功！',
              life: 3000
            });
            me.hideDialog();
            me.loadTable(me.dataTable.lastParams);
          }, (resp) => {
            if (resp.code == "900001") {
              me.$toast.add({ severity: 'warn', summary: '错误', detail: '手机号码已被使用，请更换！', life: 3000 });
            } else if (resp.code == "900002") {
              me.$toast.add({ severity: 'warn', summary: '错误', detail: '邮箱已被使用，请更换！', life: 3000 });
            }
          })
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.a-button {
  color: #12A25D;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.admin-status {
  padding: 0 10px;
  height: 22px;
  border: 1px double #91D5FF;
  color: #1890FF;
  font-size: 12px;
  background-color: #E6F7FF;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: #12A25D;
  border-color: #12A25D;
  color: #FFFFFF;
}

</style>
<style lang="scss">

</style>
